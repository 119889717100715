import { type ReactNode, type RefObject, useCallback, useRef, useEffect } from "react"
import { useStore } from "@nanostores/react"
import { motion, type PanInfo } from "framer-motion"
import cc from "classnames"

import { $toast } from "@stores/toast"
import useOutsideClick from "@hooks/useOutsideClick"

export type ToastPosition = "TOP" | "BOTTOM" | "BOTTOM_PADDING"

const BottomPaddingToast = (props: {
  is_open: boolean
  text: ReactNode
  inner_ref: RefObject<HTMLDivElement>
}): JSX.Element => {
  return (
    <div
      ref={props.inner_ref}
      className={cc(
        "fixed table mx-auto md:mx-0 left-0 md:left-auto right-0 md:right-24 w-max z-50 rounded-24 opacity-90 bg-black text-white py-8 px-12 transition-all ease-in-out duration-300",
        {
          "-bottom-40 invisible": !props.is_open,
          "bottom-88": props.is_open,
        }
      )}
    >
      {props.text}
    </div>
  )
}

const TopToast = (props: {
  is_open: boolean
  text: ReactNode
  inner_ref: RefObject<HTMLDivElement>
  onClose: () => void
}): JSX.Element => {
  const handleDragEnd = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const shouldClose = info.velocity.y < -20
    if (shouldClose) {
      props.onClose()
    }
  }

  return (
    <motion.div
      drag="y"
      dragConstraints={{ bottom: 0 }}
      ref={props.inner_ref}
      onDragEnd={handleDragEnd}
      className="fixed left-0 right-0 p-16 top-0 z-50 md:left-auto md:w-auto"
      initial={{ top: -100, display: "none" }}
      variants={{
        visible: {
          top: 0,
          y: undefined,
          display: "block",
        },
        hidden: {
          top: -100,
          y: 0,
          transitionEnd: {
            display: "none",
          },
        },
      }}
      animate={props.is_open ? "visible" : "hidden"}
    >
      <div className="opacity-90 rounded-12 bg-black text-white p-16">{props.text}</div>
    </motion.div>
  )
}

const Toast = (): JSX.Element | null => {
  const { is_open, text, position } = useStore($toast)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const ref = useRef(null)

  const close = useCallback(() => {
    $toast.setKey("is_open", false)
  }, [])

  useOutsideClick(ref, close)

  useEffect(() => {
    if (is_open) {
      // When quickly firing showToast twice, remove the old close timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(close, 5000)
    }
  }, [is_open, close])

  const handleClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    close()
  }

  // don't render the div, if it is not open.
  // The useOutsideClick hook adds a event listener to the whole document, which intercepts the first click on elements
  if (!is_open) return null

  if (position === "TOP") {
    return <TopToast text={text} is_open={is_open} inner_ref={ref} onClose={handleClose} />
  }

  if (position === "BOTTOM_PADDING") {
    return <BottomPaddingToast text={text} is_open={is_open} inner_ref={ref} />
  }

  return (
    <motion.div
      ref={ref}
      className="fixed table mx-auto md:mx-0 left-0 md:left-auto right-0 md:right-96 w-max z-50 rounded-24 opacity-90 bg-black text-white py-8 px-12 -bottom-10"
      animate={{
        y: is_open ? -128 : 0,
        opacity: is_open ? 1 : 0,
      }}
    >
      {text}
    </motion.div>
  )
}

Toast.defaultProps = {
  position: "BOTTOM",
}

export default Toast
